<script>
import { HorizontalBar } from 'vue-chartjs';
import ChartDataLabels from 'chartjs-plugin-datalabels';

export default {
  components: {
    ChartDataLabels
  },
  extends: HorizontalBar,
  props: {
    entries: {
      type: Array,
      default: () => []
    }
  },
  data: () => ({
    gradient: null,
    options: {
      indexAxis: 'y',
      responsive: true,
      maintainAspectRatio: false,
      tooltips: {
        enabled: false
      },
      layout: {
        padding: {
          right: 50
        }
      },
      legend: {
        display: false
      },
      scales: {
        xAxes: [{
          display: true,
          scaleLabel: {
            display: true
          },
          ticks: {
            fontColor: '#ccc',
            beginAtZero: true,
            fontFamily: 'DINNextLTPro-Regular'
          },
          gridLines: {
            display: true
          }
        }],
        yAxes: [{
          display: true,
          scaleLabel: {
            display: true
          },
          ticks: {
            fontSize: 16,
            fontColor: '#363534',
            fontFamily: 'DINNextLTPro-Regular'
          },
          gridLines: {
            display: false
          }
        }]
      }
    }
  }),
  watch: {
    entries(newEntries) {
      this.updateChart(newEntries);
    },
    '$i18n.locale': function() {
      this.updateChart(this.entries);
    }
  },
  mounted() {
    this.updateChart(this.entries);
  },
  methods: {
    updateChart(entrs) {
      this.addPlugin(ChartDataLabels);
      const labels = entrs.map(entry => entry.team)
        .sort()
        .map(team => `${this.$t('components.labels.teamNum', { number: `${team}` })}`);
      const data = entrs.map(entry => entry.averageTimePerHH.toFixed(3));
      this.gradient = this.$refs.canvas.getContext('2d').createLinearGradient(0, 0, 450, 0);
      this.gradient.addColorStop(0, '#74bef5');
      this.gradient.addColorStop(1, '#90e7c5');
      this.renderChart({
        labels,
        datasets: [
          {
            datalabels: {
              align: 'end',
              anchor: 'end',
              font: {
                family: 'DINNextLTPro-Regular',
                size: 16,
                color: '#363534'
              }
            },
            maxBarThickness: 15,
            label: this.$t('components.labels.averageTime'),
            borderColor: '#ff7f7f',
            backgroundColor: this.gradient,
            order: 1,
            data
          }
        ]
      }, this.options);
    }
  }
};
</script>
